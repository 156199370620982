// https://plainjs.com/javascript/

/* eslint-disable import/extensions */
import {
  wrap,
  hasParentWithMatchingSelector,
  getSelectedOptionFromSelect,
} from './vanilla.utils.js';

/**
 * Recode jquery.uniform in native JavaScript
 *
 * jquery.uniform was used to add custom styles to old form controls
 * https://github.com/AudithSoftworks/Uniform
 * https://github.com/AudithSoftworks/Uniform/downloads (for v1)
 * http://resources.olympia.ie/js/jquery.uniform/v1.7.5/
 *
 * - Used in DualListWidget filters
 *   - protected/modules/scheduling/components/Widget/views/PeriodChooser.php (#/workforce-management/employee-overview)
 * - Used in Employee - Time Sheets & Premiums - New Premium (for Cost Centre select)
 *   - protected/modules/timekeeping/components/GuidPremiumEmployeeForm.php (/#/employees/2t/time-sheets-premiums)
 * - Used in scripts/files/functions.js, (in $(window).bind('newcontent'))
 *
 * Keep same HTML structure and classes to avoid recoding CSS
 */

/**
 * Recreate jQuery uniform HTML to keep CSS intact
 * Create a span element that contains selected text
 *
 * @param select {DOMElement}
 * @returns {HTMLSpanElement}
 */
const createSelectSpan = select => {
  const textElement = document.createElement('span');
  const selected = getSelectedOptionFromSelect(select);

  // Some options seems to be populated with JS,
  // such as Client Schedule Default View in /#/system-settings/system
  if (selected) {
    textElement.textContent = selected.textContent;
  }

  return textElement;
};

/**
 * Equivalent of
 * $("select", $(obj.context))
 * .not('.selector > select, .groupFilter, select[multiple], .select-wrapper > select')
 *
 * @param context {DOMElement}
 * @returns Array
 */
export function getSelectsForUniform(context) {
  if (!context || typeof context !== 'object') {
    return [];
  }

  const selectsList = [...context.querySelectorAll('select:not(.groupFilter):not([multiple])')];

  return selectsList.filter(el => {
    return (
      !hasParentWithMatchingSelector(el, '.select-wrapper') &&
      !hasParentWithMatchingSelector(el, '.selector')
    );
  });
}

/**
 * Update wrapper classes according to select disabled attribute
 * To keep styles in sync with select state
 *
 * @param select {Element}
 */
export function updateSelectDisabledState(select) {
  if (select.hasAttribute('disabled')) {
    select.parentNode.classList.add('disabled');
  } else {
    select.parentNode.classList.remove('disabled');
  }
}

/**
 * Initialize select
 * @param context {DOMElement}
 */
export function initUniformSelect(context) {
  if (!context) {
    return;
  }

  const selectsList = getSelectsForUniform(context);

  selectsList.forEach(select => {
    const wrapper = document.createElement('div');
    wrapper.classList.add('selector');

    // Manage disabled state
    if (select.hasAttribute('disabled')) {
      wrapper.classList.add('disabled');
    }

    const dataTest = select.hasAttribute('id') ? select.getAttribute('id') : 'undefined';
    wrapper.setAttribute('data-test', `select-${dataTest}`);

    // Create selected text element and add it to select wrapper
    wrapper.prepend(createSelectSpan(select));

    wrap(select, wrapper);

    /*
     * Update custom selected text element
     * Sadly, we must use jQuery here, because .trigger('change'),
     * for instance, doesn't trigger native events
     * https://makandracards.com/makandra/71890-events-triggered-by-jquery-cannot-be-observed-by-native-event-listeners
     */
    $(select).on('change', e => {
      const span = e.currentTarget.previousSibling;
      span.textContent = getSelectedOptionFromSelect(e.currentTarget).text;
    });

    // Event triggered from protected/modules/timekeeping/components/GuidPremiumEmployeeForm.php
    select.addEventListener('updateUniformAttributes', e => updateSelectDisabledState(e.target));
  });
}
