// This is the recommended way to set the `publicPath` on the fly.
// https://webpack.js.org/guides/public-path/#on-the-fly
// This import is required to fetch the async chunks and the other resources from CloudFront.
import '../web/js/public-path';

/**
 * legacy_scripts_global.js is the entry point for any "new-legacy" modules
 * that should be exposed globally.
 * This is currently the simplest and safest way to properly import modules
 * in dev and in prod mode with Cloudfront.
 */

import { initUniformSelect } from 'web/scripts/javascript.uniform';
import { getAssetURL } from 'config/frontend-assets';

// Called in functions.js
window.initUniformSelect = initUniformSelect;

window.getAssetURL = getAssetURL;
