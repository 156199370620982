// https://plainjs.com/javascript/

/**
 * Equivalent of $.wrap(), wrap an HTML element with another element
 * @param el {Element}
 * @param wrapper {Element}
 */
export const wrap = (el, wrapper = document.createElement('div')) => {
  el.parentNode.insertBefore(wrapper, el);
  wrapper.appendChild(el)
};

/**
 * Check if an element is contained within a parent that has a specific class
 * @param target {Element}
 * @param selector {String} Class, id, attribute, etc.
 * @returns {Boolean}
 */
export const hasParentWithMatchingSelector = (target, selector) => {
  return [...document.querySelectorAll(selector)].some(el => el !== target && el.contains(target));
};

/**
 * Return selected option element
 * @param select {Element}
 */
export const getSelectedOptionFromSelect = select => {
  if (select.options.length === 0) {
    return null;
  }

  let selected = select.options[select.selectedIndex];

  if (!selected) {
    [selected] = select.querySelectorAll('option');
  }

  return selected;
};
